
import { Box, Flex, Grid, VStack, Link, Text, Image } from '@chakra-ui/react';
import { FaFacebook, FaInstagram, FaTiktok, FaTwitter } from 'react-icons/fa';

function Footer() {
  return (
    <Box as="footer" color="white" py={3}>
      <Flex justify="space-between" align="center">
      <Flex align="center" gap={4}>
          
        </Flex>
      </Flex>

      {/* Social Media Icons Row */}
      <Box >
      <VStack 
        spacing={4}
        >
        <Flex justify="center" align="center">
          {/* Social Media Icons Aligned Horizontally in Bottom Left */}
          <Flex align="center" gap={4}>
          <Link href="https://www.facebook.com/profile.php?id=61567167653646" isExternal color="brand.300" mx={2}>
            <FaFacebook size="1.5em" />
          </Link>
          <Link href="https://www.instagram.com/quantumbrew.app/" isExternal color="brand.300" mx={2}>
            <FaInstagram size="1.5em" />
          </Link>
          {/*<Link href="https://www.tiktok.com/signup" isExternal color="teal.300" mx={2}>
            <FaTiktok size="1.5em" />
          </Link>
          */}
          <Link href="https://twitter.com" isExternal color="brand.300" mx={2}>
            <FaTwitter size="1.5em" />
          </Link>
         {/*
          <Link href="/privacy-policy" color="brand.300">
            Privacy Policy
          </Link>
          <Link href="/privacy-policy" color="brand.300">
            Terms and conditions
          </Link>
          <Link href="mailto:craftsman@quantumbrew.beer" color="brand.300">
            Contact Us
          </Link>
          */}
          </Flex>
          </Flex>
          <Flex justify="center" align="center">
        <Text fontSize="sm" color="brand.300">© 2023 Quantum Brew. All rights reserved.</Text>
        </Flex>
        </VStack>
      </Box>
    
    </Box>
  );
}

export default Footer;
