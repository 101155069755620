import { background, extendTheme } from "@chakra-ui/react";

const customTheme = extendTheme({
  fonts: {
    heading: `'Nunito', sans-serif`,
    body: `'Nunito Sans', sans-serif`,
  },
  colors: {
    black: "#083D59",
    white: "#FCFAF5",
    brand: {
      50: "#A7BAC4",
      100: "#819CAA",
      200: "#5F8193",
      300: "#3F687D",
      400: "#1F4F68",
      500: "#083D59", // Default button color
      600: "#002B3B", // Hover color
      700: "#001A2B", // Active color
      800: "#001A2B",
      900: "#001A2B",
    },
    cyan: {
      50: "#BCE3F0",
      100: "#5BBBDB",
      200: "#2BA7D1",
      500: "#0095C7",
    },
    yellow: {
      500: "#F69E01",
    },
    teal: "#2F848B",
  },
  styles: {
    global: {
      body: {
        color: "#083D59",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "-0.02em",
      },
      "input, select, textarea": {
        fontFamily: `'Nunito Sans', sans-serif`,
        borderColor: "brand.100",
      },
    },
  },
  components: {
    Heading: {
      baseStyle: {
        fontWeight: "normal", // Set desired font weight
        color: "#083D59",
        lineHeight: "20px",
        letterSpacing: "-0.03em",
      },
    },
    Input: {
      baseStyle: {
        field: {
          bg: "white", // White background
          color: "gray.800", // Dark text
          borderColor: "brand.100", // Light border
          _hover: {
            borderColor: "brand.100",
          },
          _focus: {
            borderColor: "cyan.500",
          },
        },
      },
    },
    Button: {
      baseStyle: {
        fontWeight: "bold",
        textTransform: "uppercase",
        bg: "yellow.500",
        borderRadius: "md",
        borderColor: "cyan.100",
        _focus: {
          boxShadow: "outline",
        },
      },
      sizes: {
        sm: {
          fontSize: "sm",
          px: 4,
          py: 2,
        },
        md: {
          fontSize: "md",
          px: 6,
          py: 3,
        },
        lg: {
          fontSize: "lg",
          px: 8,
          py: 4,
        },
      },
      variants: {
        solid: {
          bg: "brand.500", // Background color
          color: "white", // Text color
          _hover: {
            bg: "brand.900", // Hover background color
          },
          _active: {
            bg: "brand.700", // Active background color
          },
        },
        outline: {
          border: "2px solid",
          borderColor: "brand.500",
          color: "brand.500",
          _hover: {
            bg: "brand.50",
          },
        },
        light: {
          bg: "cyan.50", // Light background
          color: "brand.500", // Dark text color
          _hover: {
            bg: "brand.500", // Slightly darker on hover
            color: "white"
          },
          _active: {
            bg: "brand.50", // Active state background
          },
        
        },
        ghost: {
          color: "brand.500",
          _hover: {
            bg: "brand.100",
          },
        },
        link: {
          color: "brand.500",
          _hover: {
            textDecoration: "underline",
          },
        },
      },
      defaultProps: {
        size: "md",
        variant: "solid", // Default variant
      },
    },
  },
});

export default customTheme;
